import * as React from "react"
import SearchFilter from "./SearchFilter/SearchFilter"
import ResultList from "./ResultList/ResultList"
import "./SearchResult.scss"
// styles
// markup
const SearchResult = () => {
 
  return (

  <React.Fragment>
    <div className="page-content">
        <SearchFilter />
        <ResultList />
   </div>
    </React.Fragment>
  )
}

export default SearchResult