import { Link } from "@StarberryUtils";
import React,{useEffect,useState, useRef} from "react";
import {Button, Navbar, Nav,Form, Container,FormControl,Row,Col } from 'react-bootstrap';
import SelectBox from "../../Home/SelectBox/SelectBox"
// Images
import  './SearchFilter.scss';

const SearchFilter = ( props ) => {
  const [scroll, setScroll] = useState(false);
  const [filter, setFilter] = useState(false);
    
  const showFilter =()=>{
      setFilter(!filter)
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 0)
    })
  }, [])

    const propertyLoc = [
        { value: 'India', label: 'India' },
        { value: 'USA', label: 'USA' },
      ];
    const propertyStatus = [
        { value: 'For Sale', label: 'For Sale' },
        { value: 'For Rent', label: 'For Rent' },
        { value: 'For Buy', label: 'For Buy' },
      ];
      const minOptions = [
        { value: '10,000 QR', label: '10,000 QR' },
        { value: '20,000 QR', label: '20,000 QR' },
        { value: '30,000 QR', label: '30,000 QR' },
      ];
      const maxOptions = [
        { value: '100,000 QR', label: '100,000 QR' },
        { value: '150,000 QR', label: '150,000 QR' },
        { value: '180,000 QR', label: '180,000 QR' },
      ];
      const propertyType = [
        { value: 'All Types', label: 'All Types' },
        { value: 'Apartment 1', label: 'Apartment 1' },
        { value: 'Apartment 2', label: 'Apartment 2' },
      ];
      const MinBed = [
        { value: '1 Bedroom', label: '1 Bedroom' },
        { value: '2 Bedrooms', label: '2 Bedrooms' },
      ];
      const MaxBed = [
        { value: '3 Bedrooms', label: '3 Bedrooms' },
        { value: '4 Bedrooms', label: '4 Bedrooms' },
      ];
      const Specification = [
        { value: 'Option 1', label: 'Option 1' },
        { value: 'Option 2', label: 'Option 2' },
      ];
  const [state,setState] = React.useState({
    logo:false,
    nave:false
  })

  return(
<div className={scroll ? "active-scroll result-filter" : "result-filter"}>
  <Container fluid >
    <h6 className={filter ? "active-result d-flex d-xl-none justify-content-between align-items-center" : "d-flex d-xl-none justify-content-between align-items-center"} onClick={e=>showFilter()}><span>Filter Results</span> <i className="arrow-icon"></i></h6>
    <Form className="result-search ">
    <div className="form-select-group d-xl-flex justify-content-between">
        <Form.Group className="prop-loc">
        <Form.Label>Property Location</Form.Label>
        <SelectBox
                placeHolder="Select Area"
                options={propertyLoc}
            />
        </Form.Group>
        <Form.Group>
        <Form.Label>Status</Form.Label>
        <SelectBox
                placeHolder="For Sale"
                options={propertyStatus}
            />
        </Form.Group>
        <Form.Group>
        <Form.Label>Min Price</Form.Label>
        <SelectBox
                placeHolder="30,000 QR"
                options={minOptions}
            />
        </Form.Group>
        <Form.Group>
        <Form.Label>Max Price</Form.Label>
        <SelectBox
                placeHolder="180,000 QR"
                options={maxOptions}
            />
        </Form.Group>
        <Form.Group>
        <Form.Label>Property Type</Form.Label>
        <SelectBox
                placeHolder="All Types"
                options={propertyType}
            />
        </Form.Group>
        <Form.Group>
        <Form.Label>Min Bedrooms</Form.Label>
        <SelectBox
                placeHolder="1 Bedroom"
                options={MinBed}
            />
        </Form.Group>
        <Form.Group>
        <Form.Label>Max Bedrooms</Form.Label>
        <SelectBox
                placeHolder="4 Bedrooms"
                options={MaxBed}
            />
        </Form.Group>
        <Form.Group>
        <Form.Label>Specifications</Form.Label>
        <SelectBox
                placeHolder="More Options"
                options={Specification}
            />
        </Form.Group>
        <Form.Group className="d-xl-none" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Include Sold" />
        </Form.Group>
        </div>
        <Button className="d-block d-xl-none" variant="primary" type="submit">
            <span>Update</span>
        </Button>
    </Form>
  </Container>
  </div>
)
  }

export default SearchFilter