import React from "react";
import Select, { components } from "react-select";
import { get, isString } from "lodash";

import "./SelectBox.scss";
//import closeIcon from "../../../assets/images/close-gray.png";

const selectBox = props => {

  //console.log("props_value", props.value);

  return (
    <div className={"select-group " + (props.value ? "" : "req_cls") + (props.isError ? "has-error" : "")}>
      <Select
        isSearchable={false}
        placeholder={props.placeHolder}
        name={props.name}
        aria-labelledby={props.id}
        defaultValue={props.options.filter(function(option) {
          return option.value === props.value;
        })}
        onBlur={props.onBlur}
        onChange={props.handleChange}
        options={props.options}
        classNamePrefix={"select-opt"}
        required={props.required}
        {...props.other}
      />
    </div>
  );
};

export default selectBox;
