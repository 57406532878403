import * as React from "react"
import { Helmet } from "react-helmet";
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import SEO from "../../components/seo";

import ResultPage from "../../components/SearchResult/SearchResult"

import "./SearchResult.scss"
// styles


// markup
const SearchResult = () => {
 	const [state,setState] = React.useState({
 		showMenu:false
 	})

 	const handlerClick = () => {
 		setState({...state,showMenu:!state.showMenu})
 	}
  
  return (

  <React.Fragment>
    <SEO title={"Property Search"} description={"Property Search"} />

    <Helmet bodyAttributes={{class: ''}}/>

    <div className={state.showMenu ? "wrapper open-search-block" : "wrapper"}>
    <Header Layout={"Without_Banner"} />
    <ResultPage />
    <Footer/>
    </div>
  </React.Fragment>
  )
}

export default SearchResult